<template>
    <div class="shop-desc-box" id="shop-desc">
      <img src="../../assets/images/back-white.png" alt="" v-if="!showtabs && $route.path === '/shop-desc' && $store.state.userAgent === 'Mobile'" class="back-img" width="30" height="30" @click="goBack">
      <tabs :tabs="['商品','详情']" ref="tabs" style="padding: 0 25%;top: 0;" :style="opacityStyle" v-else-if="showtabs" />
      <van-swipe class="my-swipe" indicator-color="white" lazy-render v-if="$store.state.userAgent === 'Mobile'">
        <van-swipe-item v-for="(imgurl, i) in swipeImgUrl" :key="i">
          <div v-lazy-container="{
               selector: 'img', error: require('../../assets/images/default-shop-desc-swiper.png'),
               loading:require('../../assets/images/default-shop-desc-swiper.png') }">
            <img :data-src="imgurl" alt="" width="100%" height="100%" style="min-height:375px;">
          </div>
        </van-swipe-item>
      </van-swipe>
      <van-swipe class="my-swipe" indicator-color="white" lazy-render autoplay="3000" v-else>
        <van-swipe-item v-for="(imgurl, i) in swipeImgUrl" :key="i">
          <div v-lazy-container="{
               selector: 'img', error: require('../../assets/images/625_625_bg.png'),
               loading:require('../../assets/images/default-shop-desc-swiper.png') }">
            <img :data-src="imgurl" alt="" width="100%" height="100%" style="min-height:375px;">
          </div>
        </van-swipe-item>
      </van-swipe>
      <div class="title-box">
        <div class="price"><span v-show="goods.originalCost">￥</span>{{ goods.minPrice }}</div>
        <div class="text">{{ goods.title }}</div>
      </div>
      <div class="shop-card">
        <img :src="logo" alt="" width="50" height="50">
        <div class="text">
          <div class="title">{{ shopName }}</div>
          <div style="display:flex;">
            <div class="span">工厂直供</div>
            <!-- <div class="span">品类多样</div> -->
            <div class="span">优质服务</div>
          </div>
        </div>
      </div>
      <div class="description" id="description" ref="description">
          <img v-lazy="url" alt="" v-for="(url, i) in descImgUrl" :key="i" width="100%">
      </div>
      <div class="collapse">
        <van-collapse v-model="activeName" @change="collapseChange">
          <van-collapse-item title="参数规格" name="1">
            <div class="size-desc">
              <div class="row">
                <div class="label">商品名称</div><div class="text">{{ goods.title }}</div>
              </div>
              <!-- <div class="row-middle">
                <div class="label">电压</div><div class="text">{{'小于等于36V'}}</div>
              </div> -->
              <!-- <div class="row-middle">
                <div class="label">品牌</div><div class="text">{{'公牛'}}</div>
              </div> -->
              <!-- <div class="row-middle">
                <div class="label">是否带光源</div><div class="text">{{'带光源'}}</div>
              </div> -->
              <!-- <div class="row">
                <div class="label">功率</div><div class="text">{{'50W及以下'}}</div>
              </div> -->
            </div>
          </van-collapse-item>
          <van-collapse-item title="价格说明" name="2">
            <div class="price-reminder">
              <div>划线价格：指商品的专柜价、吊牌价、正品零售价、厂家指导价或该商品曾经展示过的销售价等，并非原价，仅供参考。</div>
              <div class="middle-row">未划线价格：指商品的销售价，是您最终决定是否购买商品的依据。</div>
              <div>折扣：页面显示的“折扣”为非划线价格与划线价格的对比，该对比值仅供您参考，不作为计算非划线价格的依据。</div>
            </div>
          </van-collapse-item>
          <van-collapse-item title="消费提醒" name="3">
            <div class="buy-reminder">
              <div>请严格按照使用说明书要求进行使用，清洗时注意断电，避免发生烫伤等意外，产品放置在儿童触摸不到的地方，同时养成用完就断开电源、拔下插头的良好习惯。</div>
            </div>
          </van-collapse-item>
        </van-collapse>
      </div>
      <!-- <div ref="recommend">
        <div>推荐</div>
        <div>敬请期待</div>
      </div> -->
      <buy @createOrder="createOrder" :isShortage="isShortage" :showAddCartButton="true" :cartAmount="$store.state.cartAmount"/>
      <van-popup v-model="showPopup" position="bottom" round :style="'max-height: 80%; max-width:625px;'">
        <div class="order-details">
          <div class="type">
            <div class="title">款式</div>
            <div :class="[option.isSelected ? 'option-selected':'option']" v-for="(option) in options" :key="option.id" @click="selectOption(option.id)">
              <div style="display:flex;flex-wrap:nowrap;">
                <span class="label">{{ option.text }}</span>
                <span v-show="option.amount === 0" class="mark">缺货</span>
              </div>
            </div>
          </div>
          <div class="amount">
            <div class="title">
              <span>数量</span>
              <van-stepper v-model="orderInfo.amount" @change="changeAmount" input-width="40px" button-size="32px"/>
            </div>
          </div>
          <div class="button-row">
            <div class="button-buy-cart" @click="addCart" v-if="buttonType === 'addCart'">加入购物车</div>
            <div class="button-buy" @click="submitOrder" v-else>购买</div>
          </div>
        </div>
      </van-popup>
    </div>
</template>

<script>
import Buy from '../../components/footer/Buy.vue'
import { queryGoodsById, createCart, queryCartGoodsTotalAmount } from '@/api/api'
import Tabs from '../../components/tabs/index.vue'
const seo = require('../../../config/index')
import { getopenIdByCode } from '@/utils/index';

export default {
  name: 'Description',
  components: {
    Buy,
    Tabs
  },
  created(){
    getopenIdByCode()
  },
  mounted(){
    this.$bus.$on('changeTaaab', this.changeTab)
    window.addEventListener('scroll',this.handleScroll);
    if(this.$store.state.userAgent !== 'Mobile'){
        document.getElementById("shop-desc").style.cssText= 'margin-top: 58px';
    }
    this.getData()
  },
  deactivated(){
    window.removeEventListener('scroll',this.handleScroll);
  },
  data:()=>{
    return {
      swipeImgUrl:[require('../../assets/images/default-shop-desc-swiper.png')],
      descImgUrl:[
        require('../../assets/images/default-shop-desc-swiper.png'),
      ],
      mainListImgUrl:'',
      options:[{
        id:'1',
        text: '默认',
        price: 99,
        isSelected: false
      }],
      logo: seo.config.logo,
      goods:{},
      orderInfo:{
        title:'', // 标题
        amount: 1, // 数量
        type: '', // 款式
        originalCost: 0, // 单件的原价
        discount: 0, // 折扣
        totalPrice: 0, // 总价
        expressCharge: 0, // 运费
        discountPrice: 0, // 享受的优惠价格
        finalPayPrice: 0, // 最终支付价格
        imgUrl: '', // 商品图片
      },
      showPopup: false,
      activeName: [],
      showtabs: false,
      opacityStyle:{
        opacity:0
      },
      shopName: seo.config.shopName,
      appName: seo.config.appName,
      isShortage: false,
      buttonType: '',
      isLogin: localStorage.getItem('token') ? true : false,
    }
  },
  methods:{
    getData(){
      queryGoodsById({ gid: this.$route.query.gid }).then((res)=>{
        this.swipeImgUrl = res.data.data.mini_main_img_url.split(',')
        this.descImgUrl = res.data.data.mini_desc_img_url.split(',')
        this.mainListImgUrl = res.data.data.mini_main_list_img_url
        this.goods.title = res.data.data.title
        this.goods.originalCost = res.data.data.price
        this.goods.minPrice = res.data.data.minPrice
        this.goods.maxPrice = res.data.data.maxPrice
        this.isShortage = res.data.data.onSaleTotal === 0 ? true : false
        this.options.length = 0
        if(res.data.data.options.length !== 0){
            this.options = res.data.data.options
            // 购车跳转过来
            if(sessionStorage.getItem('goodDescInfo')){
              let goodDescInfo = JSON.parse(sessionStorage.getItem('goodDescInfo'))
              sessionStorage.removeItem('goodDescInfo')
              this.orderInfo.amount = goodDescInfo.amount
              this.options = this.options.map(option => ({ ...option, isSelected: false }));
              let index = this.options.findIndex(option => option.text === goodDescInfo.type);
              if (index !== -1) {
                this.options[index].isSelected = true;
              }
            }
        } else {
            let temp = {}
            let str = '默认'
            temp.id = '1'
            temp.text = str
            temp.price = res.data.data.price
            temp.isSelected = true
            this.options.push(temp)
        }
        // seo
        document.title = res.data.data.title + '-' + seo.config.appName
        let keywords = '衣柜灯,酒柜灯,橱柜灯,筒灯,小夜灯,壁灯,露营灯,无主灯,感应灯,射灯'
        let description = '在这里选购全网最精致,最实用的灯具产品,入住新家不能没有他,包括但不限于衣柜灯,酒柜灯,橱柜灯,筒灯,小夜灯,壁灯,露营灯,无主灯,感应灯,射灯'
        document.querySelector('meta[name="keywords"]').setAttribute('content', keywords)
        document.querySelector('meta[name="description"]').setAttribute('content', description)
      })
    },
    createOrder(type){
      if(!this.isShortage){
        this.buttonType = type
        this.showPopup = !this.showPopup
      }
    },
    selectOption(id){
      let tempArr = []
      let optTemp = this.options.filter(opt=>{
        return opt.id === id
      })
      if(optTemp[0].amount > 0){
        this.options.forEach((opt)=>{
          let temp = {}
          temp.id = opt.id
          temp.text = opt.text
          temp.amount = opt.amount
          temp.price = opt.price
          if(id === opt.id){
            temp.isSelected = true
            this.orderInfo.type = opt.text
            this.orderInfo.originalCost = opt.price
          } else {
            temp.isSelected = false
          }
          tempArr.push(temp)
        })
        this.options = tempArr
      }
    },
    changeAmount(val){
      this.orderInfo.amount = Number(val)
    },
    submitOrder(){
      // 默认选第一款
      let lists = []
      this.orderInfo.type = this.orderInfo.type ? this.orderInfo.type : this.options[0].text
      this.orderInfo.originalCost = this.orderInfo.originalCost ? this.orderInfo.originalCost : this.options[0].price
      if(this.orderInfo.type){
        if(this.isLogin){
          this.orderInfo.gid = this.$route.query.gid
          this.orderInfo.title = this.goods.title
          this.orderInfo.imgUrl = this.mainListImgUrl
          this.orderInfo.totalPrice = this.orderInfo.amount * 100 * this.orderInfo.originalCost / 100
          this.orderInfo.finalPayPrice = this.orderInfo.totalPrice
          this.orderInfo.shop = seo.config.shopName
          lists.push(this.orderInfo)
          sessionStorage.setItem('lists', JSON.stringify(lists))
          this.$router.push({ path:'/pay', query:{ oid: new Date().getTime(), gid: this.$route.query.gid } })
        } else {
          sessionStorage.setItem('fromPath', '/shop-desc?gid=33')
          this.$router.push({ name:'Login', params:{ text:'选商品' } })
        }
      } else {
        this.$notify({
              message: '请选择款式',
              duration: 1000,
              background: '#ec745b'
        })
      }
    },
    // 加入购物车
    addCart(){
        if(this.isLogin){
          let goodInfo = {}
          goodInfo.gid = Number(this.$route.query.gid)
          goodInfo.imgUrl = this.mainListImgUrl
          goodInfo.title = this.goods.title
          goodInfo.type = this.orderInfo.type ? this.orderInfo.type : this.options[0].text
          goodInfo.amount = this.orderInfo.amount
          goodInfo.price = this.orderInfo.originalCost ? this.orderInfo.originalCost : this.options[0].price
          goodInfo.uid = Number(JSON.parse(localStorage.getItem('userInfo'))[0].uid)
          goodInfo.shop = seo.config.shopName
          createCart({ goodInfo: goodInfo }).then(()=>{
            this.showPopup = !this.showPopup
            this.orderInfo.amount = 1 // 默认
            this.selectOption('0')
            // 重新获取购物车数量
            queryCartGoodsTotalAmount({ uid: goodInfo.uid }).then((res)=>{
              this.$store.commit('Set_CartAmount_State', res.data.total_amount)
            })
          })
        } else {
          sessionStorage.setItem('fromPath', '/shop-desc?gid=33')
          this.$router.push({ name:'Login', params:{ text:'选商品' } })
        }
    },
    getAddress(){
      
    },
    goBack(){
      let fromPath = sessionStorage.getItem('fromPath')
      if(fromPath && fromPath === '/pay'){
        this.$router.push('/')
        sessionStorage.removeItem('fromPath')
      } else {
        this.$router.back()
      }
    },
    collapseChange(){

    },
    handleScroll(){
        const top = document.documentElement.scrollTop ||  document.body.scrollTop;
        if(top < 100){
            this.showtabs = false;
            let opacity = top / 100;
            opacity = opacity > 1 ? 1 : opacity;    
            this.opacityStyle = { opacity };
        }else{
            this.showtabs = true;
            let opacity = 1
            this.opacityStyle = { opacity };
        }
        let distanceToTop = this.$refs.description.offsetTop
        if(top >= distanceToTop - 45){ // 滚动条距离顶部位置 // 详情元素的绝对位置
          this.$refs.tabs.changeTab(1, true)
        } else {
          this.$refs.tabs.changeTab(0, true)
        }
    },
    changeTab(id){
      if(id === 0){
        document.body.scrollTop && document.body.scrollTo({top: 0, behavior: 'smooth'})
        document.documentElement.scrollTop && document.documentElement.scrollTo({top: 0, behavior: 'smooth'})
      } else if(id === 1 && this.$route.path.includes('/shop-desc')){
        let distanceToTop = this.$refs.description.offsetTop - 45 // 绝对位置和tabs的height值
        window.scrollTo({
          top: distanceToTop,
          behavior: "smooth"
        });
      } else if(id === 2){
        let distanceToTop = this.$refs.recommend.offsetTop -45 // 绝对位置和tabs的height值
        window.scrollTo({
          top: distanceToTop,
          behavior: "smooth"
        });
      }
    }
  },
}
</script>

<style scoped lang="scss">
.shop-desc-box {
  min-height: 100vh;
  padding-bottom: 85px;
  position: relative;
}
.my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    text-align: center;
    background-color: #f3f3f3;
}
.title-box {
  background: #fff;
  border-radius: 5px;
  margin: 5px;
  text-align: left;
  padding: 15px 15px 20px;
  font-size: 20px;
  min-height: 90px;
  .price {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 5px;
    min-height: 27px;
    span {
      font-size: 14px;
    }
  }
  .text {
    min-height: 55px;
    display: flex;
    align-items: center;
  }
}
.shop-card {
  display: flex;
  align-items: center;
  background: #fff;
  margin: 10px 5px;
  padding: 15px 15px;
  border-radius: 5px;
  img {
    border-radius: 5px;
  }
  .text {
    margin: 0 10px 0 15px;
    text-align: left;
    font-size: 18px;
    .span {
      font-size: 14px;
      background: #eee;
      color: rgba(0,0,0,0.6);
      margin: 5px 6px 0px 0px;
      padding: 2px 10px;
      border-radius: 2px;
    }
  }
}
.tabs {
  display: flex;
  background: #fff;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  padding: 0 25%;
  border-radius: 5px;
  margin: 0 5px;
  .tab {
    font-size: 16px;
  }
}
.description {
  margin: 0 5px;
  img {
    margin: -2px 0;
  }
}
.collapse {
  margin: 0 5px;
  .size-desc {
    text-align: left;
    .row {
      display: flex;
      .label {
        width: 100px;
      }
      .text {
        flex: 1;
      }
    }
    .row-middle {
      display: flex;
      margin: 10px 0;
      .label {
        width: 100px;
      }
      .text {
        flex: 1;
      }
    }
  }
  .price-reminder {
    text-align: left;
    .middle-row {
      padding: 10px 0;
    }
  }
  .buy-reminder {
    text-align: left;
  }
}
.order-details {
  text-align: left;
  .type {
    padding: 25px 25px 0;
    .title {
      margin-bottom: 10px;
    }
    .option {
      font-size: 14px;
      margin: 10px 0;
      cursor: pointer;
      .label {
        padding: 7px 30px;
        border-radius: 25px;
        margin: 0 5px;
        background: #f3f3f3;
        border: 1px rgba(0,0,0,0.03) solid;
      }
      .mark {
        font-size: 12px;
        color: #fc5f10;
      }
    }
    .option-selected {
      font-size: 14px;
      margin: 10px 0;
      cursor: pointer;
      .label {
        padding: 7px 30px;
        border-radius: 25px;
        margin: 0 5px;
        background: #FFFAAA;
        border: 1px rgba(0,0,0,0.03) solid;
      }
    }
  }
  .amount {
    padding: 10px 25px 65px;
    padding-bottom: calc(65px + constant(safe-area-inset-bottom));
    padding-bottom: calc(65px + env(safe-area-inset-bottom));
    .title {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
    }
  }
}
.sizeParams {
  background: #fff;
  text-align: left;
  margin: 0 5px;
  font-size: 14px;
}
.priceDesc {
  background: #fff;
  text-align: left;
  margin: 0 5px;
  font-size: 14px;
}
.button-row {
  position: fixed;
  bottom: 10px;
  right: 0;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  padding-bottom: calc(-10px + constant(safe-area-inset-bottom));
  padding-bottom: calc(-10px + env(safe-area-inset-bottom));
  background: #fff;
  box-sizing: border-box;
  max-width: 625px;
  margin: 0 auto;
  left: 0;
  .button-buy {
      width: 160px;
      height: 38px;
      display: flex;
      line-height: 30px;
      border-radius: 30px;
      align-items: center;
      font-size: 14px;
      color: #fff;
      background: #fc5f10;
      justify-content: center;
      border: 1px solid #fc5f10;
      cursor: pointer;
  }
  .button-buy-cart {
    width: 160px;
    height: 38px;
    display: flex;
    margin: 0 10px;
    line-height: 30px;
    border-radius: 30px;
    align-items: center;
    font-size: 14px;
    color: #fff;
    background: #fbbc05;
    justify-content: center;
    border: 1px solid #fbbc05;
  }
}
.back-img {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1101;
  background: rgba(250, 250, 250, 0.5);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  padding: 6px 4px;
  box-sizing: border-box;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
::v-deep .van-cell__title, .van-cell__value {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
    text-align: left;
}
::v-deep .van-popup--bottom {
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
}
</style>